/* Base styles */
.app-container {
    min-height: 100svh;
    background-color: #1c0d1f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    font-family: "Open Sans";
}

.gradient-bg {
    position: absolute;
    inset: 0;
    background: linear-gradient(to bottom, rgba(136, 46, 205, 0.2), transparent);
    pointer-events: none;
}

.main-content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 42rem;
    padding: 0 1rem;
}

/* Avatar styles */
.avatar-container {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    overflow: visible;
    margin-bottom: 1rem;
    position: relative;
}

.avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

/* .avatar-ring {
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 50%;
    border: 4px solid transparent;
    transition: all 0.3s ease;
} */

/* Avatar state animations */
.userTalking .avatar-ring {
    border-color: #22c55e;
    animation: pulse-green 2s infinite;
}

.processing .avatar-ring {
    border-color: #8b5cf6;
    animation: pulse-purple 2s infinite;
}

/* .aiTalking .avatar-ring {
    border-color: #3b82f6;
    animation: pulse-blue 2s infinite;
} */

/* Text styles */
.ai-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    margin-bottom: 0.5rem;
}

.ai-role {
    color: #a78bfa;
    margin-bottom: 2rem;
}

/* Status styles */
.status-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 3rem;
}

.status-text {
    color: #d1d5db;
    min-width: 120px;
}

.status-indicator {
    display: flex;
    align-items: center;
    height: 24px;
}

/* Wave bars animation for user talking */
.wave-bars {
    display: flex;
    align-items: center;
    gap: 3px;
    height: 24px;
}

.wave-bar {
    width: 3px;
    height: 100%;
    background-color: #22c55e;
    border-radius: 3px;
    animation: wave 1s ease-in-out infinite;
}

.wave-bar:nth-child(2) {
    animation-delay: 0.1s;
}

.wave-bar:nth-child(3) {
    animation-delay: 0.2s;
}

.wave-bar:nth-child(4) {
    animation-delay: 0.3s;
}

/* Processing dots animation */
.processing-dots {
    display: flex;
    align-items: center;
    gap: 4px;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: #8b5cf6;
    border-radius: 50%;
    animation: dots 1.4s infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

/* AI wave animation */
.ai-wave {
    display: flex;
    align-items: center;
    gap: 2px;
    height: 24px;
}

.ai-wave-bar {
    width: 2px;
    height: 100%;
    background-color: #3b82f6;
    border-radius: 2px;
    animation: ai-wave 0.8s ease-in-out infinite;
}

.ai-wave-bar:nth-child(2) {
    animation-delay: 0.1s;
}

.ai-wave-bar:nth-child(3) {
    animation-delay: 0.2s;
}

.ai-wave-bar:nth-child(4) {
    animation-delay: 0.3s;
}

.ai-wave-bar:nth-child(5) {
    animation-delay: 0.4s;
}

.ai-wave-bar:nth-child(6) {
    animation-delay: 0.5s;
}

/* Controls styles */
.controls-container {
    display: flex;
    align-items: center;
    gap: 3rem;
    position: absolute;
    bottom: -9rem;
}

.control-button {
    padding: 1.25rem;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mute-button {
    background-color: rgba(139, 92, 246, 0.2);
    color: #8b5cf6;
}

.mute-button:hover {
    background-color: rgba(139, 92, 246, 0.3);
}

.muted {
    background-color: rgba(239, 68, 68, 0.2);
    color: #ef4444;
}

.hangup-button {
    background-color: #ef4444;
    color: white;
}

.hangup-button:hover {
    background-color: #dc2626;
}

/* Logo styles */
.logo-container {
    position: absolute;
    top: 1.5rem;
    left: .5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.logo-text {
    color: white;
    font-size: 30px;
    font-weight: 600;
}

/* Premium badge styles */
.premium-badge {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}

.premium-badge span {
    background-color: rgba(139, 92, 246, 0.2);
    color: #a78bfa;
    padding: 0.25rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
}

.start-button {
    background-color: #58009f;
    color: white;
    border-radius: 1.3rem;
    font-size: 24px;
    gap: .6em;
    padding: 20px 20px;
    background-color: rgba(139, 92, 246, 0.2);
    color: #8b5cf6;
}

/* Animations */
@keyframes wave {

    0%,
    100% {
        height: 8px;
    }

    50% {
        height: 24px;
    }
}

@keyframes dots {

    0%,
    100% {
        transform: scale(0.6);
        opacity: 0.6;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes ai-wave {

    0%,
    100% {
        height: 6px;
    }

    50% {
        height: 24px;
    }
}

@keyframes pulse-green {
    0% {
        box-shadow: 0 0 0 0 rgba(34, 197, 94, 0.4);
    }

    70% {
        box-shadow: 0 0 0 20px rgba(34, 197, 94, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(34, 197, 94, 0);
    }
}

@keyframes pulse-purple {
    0% {
        box-shadow: 0 0 0 0 rgba(139, 92, 246, 0.4);
    }

    70% {
        box-shadow: 0 0 0 20px rgba(139, 92, 246, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(139, 92, 246, 0);
    }
}

@keyframes pulse-blue {
    0% {
        box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.4);
    }

    70% {
        box-shadow: 0 0 0 20px rgba(59, 130, 246, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    }
}

@media (max-height: 736px) {
    .controls-container {
        bottom: -6rem;
    }
}

.avatar-container {
    position: relative;
    display: inline-block;
}

/* Base style for the avatar ring */
.avatar-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 220px; */
    /* Adjust as needed */
    /* height: 220px; */
    border: 12px solid transparent;
    border-radius: 50%;
    pointer-events: none;
    z-index: -1;
}

/* When the agent is speaking, add an animated ring effect */
.aiTalking .avatar-ring {
    border: none;
    animation: pulse 1.5s infinite;
}

/* Example keyframes for a pulse animation */
@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    70% {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 0.7;
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

.avatar-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 190px;
    /* Adjust size as needed */
    height: 190px;
    border-radius: 50%;
    /* background: conic-gradient(#E94FE9,
            #BA55D3,
            #9370DB,
            #BA55D3,
            #E94FE9); */
    animation: rotateRing 2s linear infinite;
    box-shadow: 0 0 15px 5px rgba(185, 85, 211, 0.6);
    opacity: 0;
    /* Hidden by default */
    transition: opacity 0.3s ease;
}

.aiTalking .avatar-ring {
    opacity: 1;
    /* Show the ring when agent is speaking */
}

.avatar-ring2 {
    opacity: 0;
}

.avatar-ring2.show {
    /* border: 4px solid #8b5cf6; */
    border-radius: 50%;
    width: 240px;
    height: 240px;
    opacity: 1;
    position: absolute;
    top: 0px;
    animation: rotateRing2 2s linear infinite;
}

@keyframes rotateRing {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes rotateRing2 {
    0% {
        box-shadow: 0 0 0 0 #8b5cf666;
    }

    70% {
        box-shadow: 0 0 0 20px #8b5cf600;
    }

    100% {
        box-shadow: 0 0 0 0 #8b5cf600;
    }
}

.call-status {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(136, 46, 205, 0.1);
    backdrop-filter: blur(4px);
    padding: 1rem 1.5rem;
    border-radius: 12px;
    color: #fff;
    font-size: 0.9rem;
    z-index: 100;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.call-status.fade-out {
    opacity: 0;
    visibility: hidden;
}

.loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(136, 46, 205, 0.3);
    border-top-color: #882ecd;
    border-radius: 50%;
    animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}